import React, { useEffect, useState } from "react";
import { UserNotification } from "models/Interfaces";
import { showShortURL } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getBlockchainForAssets } from "models/utils/blockchainTranslations";
import { CopyButton } from "components/CopyButton";

const NewSaleCard: React.FC<{
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);

  const [collectionAddress, setCollectionAddress] = useState<null | string>(
    null
  );
  const [collectionName, setCollectionName] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  let blockchain: string | null;

  const getCollection = async () => {
    try {
      const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${blockchain}/${process.env.REACT_APP_ASSETS_NFT_CONTRACTS}`;
      const connection = await fetch(url, {
        method: "GET",
      });
      const collections = await connection.json();
      setCollectionAddress(notification.collection);
      setCollectionName(collections[notification.collection].name);
    } catch (error) {
      //! this must be removed once Assets have terra classic on governance
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    blockchain = getBlockchainForAssets(notification.chainId);
    getCollection();
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center h-1/6">
        <span className="text-gray-semibold">
          <FontAwesomeIcon
            icon={faTags}
            className="bg-transparent"
            size="sm"
            style={{ marginRight: "10px" }}
          />
        </span>
        <h6 className="font-semibold">
          {t("notifications.collection", { ns: "common" })}
        </h6>
      </div>
      <div className="flex items-center transition h-4/6">
        <div className="text-sm">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {(!isLoading && collectionName) || showShortURL(collectionAddress!)}
        </div>
        <CopyButton
          hoverText={t("notifications.copy_address", "common")}
          clickText={t("notifications.copied", "common")}
          isCopying={copy}
          onCopy={copyToClipboard}
          copiedElement={notification?.collection}
        />
      </div>
    </div>
  );
};
export default NewSaleCard;
