export const Badge: React.FC<{
    text: string;
    bgColor?: string;
    textColor?: string;
}> = ({
    text,
    bgColor = "gold",
    textColor = "white",
}) => {

    return (
        <div className={`bg-${bgColor} text-${textColor} px-2 text-xs leading-4 rounded-10px text-center`}>
            {text}
        </div>
    )
};