import React, { useEffect, useState } from "react";
import { LiquidStakingFlagsNames, UserNotification } from "models/Interfaces";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import ReactCardFlip from "react-card-flip";
import { showShortURL } from "../utils";
import { CopyButton } from "components/CopyButton";

const StakingCard: React.FC<{
  notification: UserNotification;
  getTypeDesc: Function;
  isFlipped: boolean;
  copy: boolean;
  copyToClipboard: Function;
  liquidStakingTypeAlerts: LiquidStakingFlagsNames[];
}> = ({
  notification,
  getTypeDesc,
  isFlipped,
  liquidStakingTypeAlerts,
  copy,
  copyToClipboard,
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const [walletAddress, setWalletAddress] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  const options = liquidStakingTypeAlerts?.filter((option) => {
    return option.flag;
  });

  const getDisplayName = (name: string) => {
    return t(`staking_options.${name}`, {
      ns: "enumerations",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setWalletAddress(notification.walletAddress);
    setIsLoading(false);
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center h-1/6">
        <span className="text-gray-semibold">
          <FontAwesomeIcon
            icon={faTags}
            className="bg-transparent"
            size="sm"
            style={{ marginRight: "10px" }}
          />
        </span>

        <h6 className="font-semibold">
          {t(`scope_notifications.liquid_staking.${notification.scope}`, {
            ns: "enumerations",
          })}
        </h6>
      </div>

      <div className="flex items-center transition h-4/6 text-sm text-gray-darksm">
        {isLoading && <div className="loading-ring gray-dark"></div>}
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="vertical"
          cardStyles={{
            front: { height: "94.35px" },
            back: { height: "94.35px" },
          }}
        >
          <div className="flex items-center justify-between transition h-full">
            <div className="flex items-center ">
              <span className="text-sm">
                {isLoading && <div className="loading-ring gray-dark"></div>}
                {!isLoading && showShortURL(notification.walletAddress)}
              </span>
              <CopyButton
                hoverText={t("notifications.copy_address", "common")}
                clickText={t("notifications.copied", "common")}
                isCopying={copy}
                onCopy={copyToClipboard}
                copiedElement={notification?.walletAddress}
              />
            </div>
          </div>
          <div className="flex items-center justify-between transition h-full">
            <ul className="list-disc ml-7 text-sm">
              {options.map((option, index) => {
                return (
                  <li key={Math.random() * 0.05 + index} className="flex">
                    <p className="text-sm ">
                      {getDisplayName(option.name)}
                      <span className="text-sm mx-1 ">{option.type}</span>
                      <span className="text-sm ">{option.value}</span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </ReactCardFlip>
      </div>
    </div>
  );
};
export default StakingCard;
