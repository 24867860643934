import classNames from "classnames";
import { MouseEvent, ReactNode, useState } from "react";

export const enum TooltipStyle {
  clear = "clear",
  dark = "dark",
}

export const enum TooltipStyleFont {
  thin = "thin",
  base = "base",
  medium = "medium",
}
export const Tooltip: React.FC<{
  children: ReactNode;
  content: ReactNode;
  isTooltipContentShow?: boolean;
  showTooltipOnLeave?: boolean;
  tooltipStyle?: TooltipStyle;
  topPosition?: string;
  fontStyle?: TooltipStyleFont;
}> = ({
  children,
  content,
  showTooltipOnLeave = false,
  isTooltipContentShow = false,
  tooltipStyle = TooltipStyle.clear,
  topPosition,
  fontStyle = TooltipStyleFont.medium,
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [mouseLocation, setMouseLocation] = useState({
    x: 0,
    y: 0,
  });

  const handleMouseEnter = (event: MouseEvent) => {
    setMouseLocation({
      x: event.pageX,
      y: event.pageY,
    });
    setIsTooltipShown(true);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsTooltipShown(false)}
    >
      <span>{children}</span>
      <div
        className={classNames(
          `fixed max-w-1/5 text-sm rounded p-1.5 z-50 font-${fontStyle} `,
          {
            "bg-white shadow-lg": tooltipStyle === TooltipStyle.clear,
            "bg-gray-extralight90 text-white":
              tooltipStyle === TooltipStyle.dark,
          }
        )}
        style={{
          display: isTooltipShown ? "block" : "none",
          top: topPosition || mouseLocation.y,
          left: mouseLocation.x,
        }}
      >
        {content}
      </div>
    </div>
  );
};
