import { Dispatch, SetStateAction } from "react";

export const TermsValidation: React.FC<{
  setIsTermsChecked: Dispatch<SetStateAction<any>>;
  isTermsChecked: boolean;
}> = ({ setIsTermsChecked, isTermsChecked }) => {
  const handleTermsCheck = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  return (
    <div className="w-11/12 md:w-7/12 mb-8 text-center">
      <label>
        <input
          type="checkbox"
          checked={isTermsChecked}
          className="form-checkbox text-gold rounded-sm
          focus:ring
          focus:ring-transparent"
          onChange={handleTermsCheck}
        />
        <span className="text-sxm ml-2">
          By creating an account you agree to the{" "}
          <a
            href="https://hermesprotocol.io/terms"
            target="_blank"
            className={"text-muddywaters-500 hover:text-gray-800 underline"}
          >
            terms of use
          </a>{" "}
          and our{" "}
          <a
            href="https://hermesprotocol.io/privacy_policy"
            target="_blank"
            className={"text-muddywaters-500 hover:text-gray-800 underline"}
          >
            privacy policy
          </a>
        </span>
      </label>
    </div>
  );
};
