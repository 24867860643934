import { PlatformType } from "models/Enums";
import React from "react";
import { useContextBlockchainData } from "contexts/blockchain-data";

const PlatformIcon: React.FC<{
  platform: PlatformType | undefined;
  className: string | undefined;
}> = ({ platform, className }) => {
  const { projects } = useContextBlockchainData();

  const platformIcon = projects?.filter((p) => {
    return p?.project_market_id === platform;
  })[0]?.image_url;

  return platformIcon ? (
    <img className={className} src={platformIcon} alt="" />
  ) : (
    <div></div>
  );
};

export default PlatformIcon;
