import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const CopyButton: React.FC<{
    hoverText?: string;
    clickText?: string;
    isCopying: boolean;
    copiedElement: string;
    onCopy: Function;
}> = ({
    hoverText = "Copy",
    clickText = "Copied",
    isCopying = false,
    copiedElement,
    onCopy
}) => {
        const [isTooltipShown, setIsTooltipShown] = useState(false);

        const handleMouseEnter = () => {
            setIsTooltipShown(true);
        };

        const handleMouseLeave = () => {
           setIsTooltipShown(false);
        };

        const handleOnClickCopy = (param: string) => {
            setIsTooltipShown(false);
            onCopy(param);
        };
        return (
            <div className="relative">
                <div className="ml-4 mr-4 cursor-pointer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleOnClickCopy(copiedElement)}>
                    <div className="bg-gray-extralight30 rounded-full w-6 h-6 flex items-center justify-center">
                        <FontAwesomeIcon
                            size="xs"
                            icon={faCopy}
                            className={"bg-transparent text-gray-medium"}
                        />
                    </div>
                </div>
                <div className="absolute mt-2 inset-x-0">
                    {!isCopying && (
                        <div className="rounded bg-gray-extralight90 text-white text-xs text-center"
                            style={{ display: isTooltipShown ? "block" : "none" }}>
                            {hoverText}
                        </div>
                    )}
                    {isCopying && (
                        <div className="rounded bg-gray-extralight90 text-white text-xs text-center">
                            {clickText}
                        </div>
                    )}
                </div>
            </div>
        );
    };