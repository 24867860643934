import { SettingsTypes } from "../../views/Settings";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  faWallet,
  faComments,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { IconSettings } from "./IconSettings";
import Button, { ButtonSize, ButtonType } from "components/Button";
import { SocialAccounts, WalletsFlow } from "components/modals";
import PopUp, { PopupSize } from "components/Popup";
import { useState } from "react";


type Props = {
  type?: string;
  dynamicTitle?: string;
  stylingTitle?: string;
  hasTitleIcon?: boolean;
  hasTitlePlus?: boolean;
};

export const TitleSection = ({
  type,
  hasTitleIcon = true,
  hasTitlePlus,
  stylingTitle,
  dynamicTitle,
}: Props) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex">
        <div className="flex w-full">
          {hasTitleIcon && (
            <div
              className={classNames(
                "flex justify-center items-center mr-2 border-2 border-white rounded-full h-9 w-9",
                { "bg-walletsContainer": type === SettingsTypes.wallets },
                {
                  "bg-socialAccountsContainer":
                    type === SettingsTypes.socialAccounts,
                },
                {
                  "bg-otherSettingContainer":
                    type === SettingsTypes.otherSettings,
                }
              )}
            >
              <FontAwesomeIcon
                className={classNames(
                  "",
                  {
                    "text-walletsIcon ": type === SettingsTypes.wallets,
                  },
                  {
                    "text-socialAccountsIcon ":
                      type === SettingsTypes.socialAccounts,
                  },
                  {
                    "text-otherSettingIcon ":
                      type === SettingsTypes.otherSettings,
                  }
                )}
                icon={
                  type === SettingsTypes.wallets
                    ? faWallet
                    : type === SettingsTypes.socialAccounts
                    ? faComments
                    : faTasks
                }
              />
            </div>
          )}
          <h4 className={`${stylingTitle && stylingTitle}`}>
            {dynamicTitle && dynamicTitle}
          </h4>
          <h4 className={classNames("my-2 text-black h-3")}>
            {type === SettingsTypes.wallets &&
              t("settings.title.wallets", { ns: "common" })}
            {type === SettingsTypes.socialAccounts &&
              t("settings.title.social_accounts", { ns: "common" })}
            {type === SettingsTypes.otherSettings &&
              t("settings.title.other_settings", { ns: "common" })}
          </h4>
        </div>
        {hasTitlePlus && (
          <Button
            onClick={(e) => {
              setShowModal(true);
            }}
            type={ButtonType.backgroundNone}
            size={ButtonSize.clear}
            className={`mx-2 mb-1 flex p-0 m-0 disabled:shadow-lg cursor:pointer  `}
          >
            <IconSettings
              icon="plus"
              shadowIcons={true}
              iconStyle="fas"
              fontSize="text-base"
              textColor="text-gold"
              fontMobile="text-base"
              className="w-10 h-10 "
            />
          </Button>
        )}

        {showModal && (
          <PopUp closeModal={handleCloseModal} size={PopupSize.smallxs}>
            {type === SettingsTypes.socialAccounts && (
              <SocialAccounts closeModal={handleCloseModal} />
            )}
            {type === SettingsTypes.wallets && (
              <WalletsFlow closeModalMain={handleCloseModal} />
            )}
          </PopUp>
        )}
      </div>
    </>
  );
};
