import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserNotification } from "models/Interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { CopyButton } from "components/CopyButton";

const NewSale = ({
  disabledBtn,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const [copy, setCopy] = useState<string | null>(null);

  disabledBtn && disabledBtn(false);


  const copyToClipboard = (word: string) => {
    navigator.clipboard.writeText(word);
    setCopy(word);
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(null);
      }, 2000);
    }
  }, [copy]);

  return (
    <>
      {!notification && (
        <>
          <div className="pb-4 border-b border-black-line w-full ">
            <h3>{t("notifications.governance_poll", { ns: "common" })}</h3>
          </div>
        </>
      )}
      <div className="w-5/6 my-6 mx-auto">
        <div
          className="mb-4 cursor-pointer"
        >
          <p>
            {t("notifications.server_id", { ns: "common" })}
          </p>
          <div className="flex items-center">
            <input
              type="text"
              value={notification?.publicId.groupId}
              placeholder={`Server Id`}
              className={`cursor-pointer text-sm rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-gray-medium`}
              disabled
            />
            <CopyButton
              hoverText={t("notifications.copy", "common")}
              clickText={t("notifications.copied", "common")}
              isCopying={copy === notification?.publicId.groupId! ? true : false}
              onCopy={copyToClipboard}
              copiedElement={notification?.publicId.groupId!}
            />
          </div>
          <p className="mt-3">
            {t("notifications.channel_id", { ns: "common" })}
          </p>
          <div className="flex items-center">
            <input
              type="text"
              value={notification?.publicId.channelId}
              placeholder={`Channel Id`}
              className={`focus:ring-0 focus:opacity-100 focus:border-gray-extralight90medium focus:outline-none text-sm rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-gray-medium`}
              disabled
            />
            <CopyButton
              hoverText={t("notifications.copy", "common")}
              clickText={t("notifications.copied", "common")}
              isCopying={copy === notification?.publicId.channelId! ? true : false}
              onCopy={copyToClipboard}
              copiedElement={notification?.publicId.channelId!}
            />
          </div>
        </div>
        <div className="mt-6 p-4 shadow-sm bg-gold10 rounded flex">
          <div className="flex grow-0 justify-center text-white mr-4">
            <span
              className={`w-6 h-6 leading-5 p-1 text-xs rounded-full text-center bg-gold`}
            >
              <FontAwesomeIcon icon={faExclamation} />
            </span>
          </div>
          <div>
            <div className="mb-4">
              {t("notifications.nft_sale_part1", { ns: "common" })}
            </div>
            <div>{t("notifications.nft_sale_part2", { ns: "common" })}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSale;