import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import RightSection from "../components/RightSection";
import "./../style.css";
import "../i18n.js";
import BottomMenu from "../components/BottomMenu";
import { useTranslation } from "react-i18next";
import ContainerCard from "../components/settingsCards/ContainerCard";
import { Navbar } from "components/Navbar";
import PopUp, { PopupSize } from "components/Popup";
import { Warning } from "components/modals";

export enum SettingsTypes {
  otherSettings = "otherSettings",
  wallets = "wallets",
  socialAccounts = "socialAccounts",
}

export enum ModalState {
  hasSocial = "hasSocial",
}

export const SettingsPage: React.FC<{
  error: string | null;
  setError: Dispatch<SetStateAction<any>>;
}> = ({ error, setError }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);

  const handleWarningText = () => {
    if (modalContent) {
      return t("modal_content.has_social", {
        ns: "enumerations",
      });
    } else if (error) {
      return error;
    }
  };

  const handleTryAgain = () => {
    setTimeout(() => {
      setError(null);
      setIsOpenModal(false);
      setModalContent(null);
    }, 2000);
  };

  useEffect(() => {
    if (error && error === "elementExists") {
      setIsOpenModal(true);
      setModalContent(ModalState.hasSocial);
    } else if (error) {
      setIsOpenModal(true);
    }
  }, [error]);

  useEffect(() => {
    setNavbarWidth(document.getElementById("sideMenu")?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
        <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
        <RightSection isCondensed={isCondensed}>
          <div className="relative md:w-full flex items-center md:px-6 pb-8 max-w-6xl">
            <div className="px-2 md:px-0 center flex-col w-full">
              <section className="flex flex-col w-full">
                <h1 className="mt-2 w-full justify-between mb-3 ml-4">
                  {t(`menu.settings`, { ns: "common" })}
                </h1>
                <ContainerCard
                  hasTitlePlus={true}
                  type={SettingsTypes.wallets}
                />
                <ContainerCard
                  hasTitlePlus={true}
                  type={SettingsTypes.socialAccounts}
                />
                <ContainerCard type={SettingsTypes.otherSettings} />
              </section>
            </div>
          </div>
        </RightSection>
        <BottomMenu />
      {isOpenModal && (
        <PopUp size={PopupSize.small}>
          <Warning
            title={"Warning"}
            text={handleWarningText()}
            iconWarning={true}
          />
          {handleTryAgain()}
        </PopUp>
      )}
    </>
  );
};
