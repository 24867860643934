import Button, { ButtonType } from "components/Button";
import Logo from "components/Logo";
import { ReactNode, useEffect } from "react";

export interface ModalContent {
  logo?: boolean;
  modalImage?: string;
  modalTitle?: string;
  name?: string;
  additionalTitle?: string;
  children?: ReactNode | void;
  description?: string;
  alignElements?: string;
  primaryButton?: string;
  secondaryButton?: string;
  primaryButtonOnClick?: React.MouseEventHandler;
  secondaryButtonOnClick?: React.MouseEventHandler;
}

const ModalAuth = ({
  logo,
  modalImage,
  modalTitle,
  name,
  additionalTitle,
  children,
  description,
  alignElements = "",
  primaryButton,
  secondaryButton,
  primaryButtonOnClick,
  secondaryButtonOnClick,
}: ModalContent) => {
  const handleTextTransform = (
    text: string | undefined,
    id: string,
    name: string | undefined
  ) => {
    if (text === undefined) return;
    let res = text.replace("[*", "<b>").replace("*]", "</b>");
    res = res.replace("[_", "<u>").replace("_]", "</u>");
    res = res.replace("[/", "<br />");
    if (name) {
      res = res.replace("[<name>]", name);
    }
    const el = document.getElementById(id);
    if (el) return (el.innerHTML = res);
  };

  useEffect(() => {
    handleTextTransform(modalTitle, "modalTitle", name);
    handleTextTransform(additionalTitle, "additionalTitle", name);
    handleTextTransform(description, "description", name);
  }, []);

  return (
    <>
      {logo && <Logo logout={true} />}
      {modalImage && (
        <img src={modalImage} alt="" className={`w-8/12 md:w-4/12 m-8`} />
      )}
      {modalTitle && (
        <h1 id="modalTitle" className="text-xl font-semibold my-4 md:mb-8 text-center">
          {handleTextTransform(modalTitle, "modalTitle", name)}
        </h1>
      )}
      {additionalTitle && (
        <h2
          id="additionalTitle"
          className={`text-lg font-normal ${alignElements} w-11/12 md:w-9/12 text-center additionalTitle`}
        >
          {handleTextTransform(additionalTitle, "additionalTitle", name)}
        </h2>
      )}
      <br />
      <div className="flex justify-evenly">
        <div className="mx-10">
          {primaryButton && (
            <Button type={ButtonType.cancel} onClick={primaryButtonOnClick}>
              {primaryButton}
            </Button>
          )}
        </div>
        <div className="mx-10">
          {secondaryButton && (
            <Button onClick={secondaryButtonOnClick}>{secondaryButton}</Button>
          )}
        </div>
      </div>
      <>{children}</>
    </>
  );
};

export default ModalAuth;
