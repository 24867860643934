import React, { useEffect, useState } from "react";
import { UserNotification } from "models/Interfaces";
import { showShortURL } from "../utils";
import { useTranslation } from "react-i18next";
import { CopyButton } from "components/CopyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

const WalletWatcherCard: React.FC<{
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const [walletAddress, setWalletAddress] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setWalletAddress(notification.walletAddress);
    setIsLoading(false);
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center h-1/6">
        <span className="text-gray-semibold">
          <FontAwesomeIcon
            icon={faTags}
            className="bg-transparent"
            size="sm"
            style={{ marginRight: "10px" }}
          />
        </span>
        <h6 className="font-semibold">
          {t("notifications.wallet", { ns: "common" })}
        </h6>
      </div>
      <div className="flex items-center transition h-4/6">
        <span className="text-sm">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && showShortURL(notification.walletAddress)}
        </span>
        <CopyButton
          hoverText={t("notifications.copy_address", "common")}
          clickText={t("notifications.copied", "common")}
          isCopying={copy}
          onCopy={copyToClipboard}
          copiedElement={notification?.walletAddress}
        />
      </div>
    </div>
  );
};
export default WalletWatcherCard;
