import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  title?: string;
  text?: string;
  additionalText?: string;
  iconWarning?: boolean;
  iconSuccess?: boolean;
  colorIcon?: string;
};

export const Warning = ({
  text,
  additionalText,
  title,
  iconWarning,
  iconSuccess,
  colorIcon = "bg-gray-dark",
}: Props) => {
  return (
    <div className="text-center px-4">
      <div className="flex grow-0 justify-center text-white">
        <span
          className={`w-10 h-10 leading-9 p-1 text-xl rounded-full text-center ${colorIcon}`}
        >
          {iconSuccess && <FontAwesomeIcon icon={faCheck} />}
          {iconWarning && <FontAwesomeIcon icon={faExclamation} />}
        </span>
      </div>
      <h3 className=" my-4">{title ?? "Title"}</h3>
      <p className="text-lg mt-4">{text ?? ""}</p>
      {additionalText && <p className="text-lg mt-4">{additionalText}</p>}
    </div>
  );
};
